<template>
  <div class="story-page main">
    <div class="bg-black">
      <div class="ss-container">
        <h6 class="section-title">Story</h6>
        <p class="section-desc">那些小故事</p>
      </div>
    </div>
    <div class="bg-black black-section">
      <ListTemplate :data="stories" category="story"></ListTemplate>
    </div>
  </div>
</template>

<script>
import ListTemplate from "@/components/ListTemplate.vue";
import Stories from "@/data/stories.js";
export default {
  name: "Story",
  components: {
    ListTemplate
  },
  data() {
    return {
      stories: Stories
    };
  }
};
</script>

<style lang="sass" scoped>
.story-page
  padding-top: 130px
  background-color: #111111
  +deviceWidth()
    padding-top: 50px
.black-section
  padding-bottom: 48px
  +deviceWidth()
    padding-bottom: 30px
</style>
